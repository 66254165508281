<template>
    <div class="Productplan">
        <div class="rana_cons">
            <div class="rana_con_title">
                同购买场景下的竞品分析
            </div>
            <div class="Compet_div clearfix">
                <div class="Prod_left fl">
                    <img src="./../../../assets/img/ght.png" alt="">
                    <div class="Prod_total">
                        <p class="Prod_P">产品规格</p>
                        <div class="Prod_to_div">
                            <el-radio-group v-model="radio" :disabled="true">
                                <el-radio 
                                    v-for="(citem,cindex) in radioList"
                                    :label="citem.text"  
                                    :key="cindex">
                                    {{citem.text}}
                                </el-radio>
                            </el-radio-group>
                        </div>
                    </div>
                    <div class="Prod_ten Prod_l_one" :class="num==1?'Prod_tens':''" v-preventReClick @click="oBox(1)">
                        <i class="iconfonts icons-yewu" style="font-size:22px"></i>
                        <span>关键业务</span>
                    </div>
                    <div class="Prod_ten Prod_l_two" :class="num==2?'Prod_tens':''" v-preventReClick @click="oBox(2)">
                        <i class="iconfonts icons-renliziyuan" style="font-size:22px"></i>
                        <span>人力渠道</span>
                    </div>
                    <div class="Prod_ten Prod_l_three" :class="num==3?'Prod_tens':''" v-preventReClick @click="oBox(3)">
                        <i class="iconfonts icons-cailiguanli" style="font-size:22px"></i>
                        <span>物力财力</span>
                    </div>
                    <div class="Prod_ten Prod_l_four" :class="num==4?'Prod_tens':''" v-preventReClick @click="oBox(4)">
                        <i class="iconfonts icons-fazhanguihua" style="font-size:22px"></i>
                        <span>发展计划</span>
                    </div>
                    <div class="Prod_ten Prod_l_five" :class="num==5?'Prod_tens':''" v-preventReClick @click="oBox(5)">
                        <i class="iconfonts icons-jihui" style="font-size:22px"></i>
                        <span>竞争机会</span>
                    </div>
                    <div class="Prod_ten Prod_l_six" :class="num==6?'Prod_tens':''" v-preventReClick @click="oBox(6)">
                        <i class="iconfonts icons-shichang" style="font-size:22px"></i>
                        <span>市场缝隙</span>
                    </div>
                </div>
                <div class="Prod_right fl">
                    <div class="_right_ssss">
                        <div class="P_right_one" v-show="num==1 || num == 3 || num==5 || num==6">
                            <p v-for="(item,index) in form" :key="index">
                                {{item}}
                            </p>
                        </div>
                        <div class="P_right_two" v-show="num==2">
                            <ul class="p_r_one clearfix" v-for="(Litem,Lindex) in form" :key="Lindex">
                                <li class="fl p_r_one_li" v-for="(Litems,Lindexs) in Litem" :key="Lindexs" :title="Litems.val">
                                    <span>
                                        {{Litems.val}}
                                    </span> 
                                </li>
                            </ul>
                        </div>
                        <div class="P_right_four" v-show="num==4">
                            <div class="_four_lines" id="main">

                            </div>
                        </div>
                    </div>   
                </div>
            </div>
            <!-- 按钮 -->
            <div class="Com_butt_div">
                <div class="Com_button_one" v-preventReClick @click="prevstep()">
                    <span>返回首页</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import * as echarts from 'echarts';
export default {
    data() {
        return {
            radio:1,
            cname:'',
            num:1,
            form:[],
            radioList:[],
            tNum:1
        }
    },
    methods: {
        getapp(val){
            this.axios.projectS6P1({
                token:this.$store.state.token,
                id:this.$store.state.cla_id,
            }).then(res=>{
                if(res.data.code==0){
                    // var arr = res.data.data.data.cpgg
                    //     arr.map(el=>{
                    //         if(el.val==1){
                    //             el.val = true
                    //         }else if(el.val == 0){
                    //             el.val = false
                    //         }
                    //     })
                    var str = res.data.data.qrcode
                    this.$emit('info', str);
                    this.allform = res.data.data.data
                    res.data.data.data.cpgg.forEach(el=>{
                        if(el.val==1){
                            this.radio = el.text
                        }
                    })
                    this.radioList = res.data.data.data.cpgg
                    if(val==1){
                        this.form = res.data.data.data.gjyw
                    }else if(val==2){
                        this.form = res.data.data.data.rlqd
                    }else if(val==3){
                        this.form = res.data.data.data.wlcl
                    }else if(val==4){
                        this.form = res.data.data.data.fzgh
                        this.ser1 = res.data.data.data.series[0]
                        this.ser2 = res.data.data.data.series[1]
                        this.ser3 = res.data.data.data.series[2]
                        this.ser4 = res.data.data.data.series[3]
                        this.$nextTick(function () {
                            setTimeout(() => {
                                this.mychart()
                            }, 100)
                        });
                    }else if(val==5){
                        this.form = res.data.data.data.jzjh
                    }else if(val==6){
                        this.form = res.data.data.data.scfx
                    }
                    this.$nextTick(function () {
                            this.num = val
                    });
                }else{
                    this.$message.error(res.data.message)
                }
            }).catch(err=>{

            })
        },
        mychart(){
            var chartDom1 = document.getElementById('main')
            var mcChart = echarts.init(chartDom1);
            let option = {
                color:['#0D7FD7','#0DCBD7'],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    }
                },
                toolbox: {
                    feature: {
                        // dataView: { show: true, readOnly: false },
                        // magicType: { show: true, type: ['line', 'bar'] },
                        // restore: { show: true },
                        // saveAsImage: { show: true }
                    }
                },
                legend: {
                    data: [
                        {
                            name:'销售',
                            textStyle: {
                                color: '#fff',
                                fontSize:14
                            }
                        },
                        {
                            name:'营收',
                            textStyle: {
                                color: '#fff',
                                fontSize:14
                            }
                        }
                    ]
                },
                xAxis: [
                    {
                        type: 'category',
                        data: ['第一年','第二年','第三年'],
                        axisPointer: {
                            type: 'shadow'
                        },
                        axisLine:{
                            show :true,
                                // symbol: ['none', 'arrow'],
                            lineStyle:{
                                color:'#fff'
                            }
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '营收（万元）',
                        axisLabel: {
                            formatter: '{value}'
                        },
                        axisLine:{
                            show :true,
                                // symbol: ['none', 'arrow'],
                            lineStyle:{
                                color:'#fff'
                            }
                        }
                    },
                    {
                        type: 'value',
                        name: '销售（套）',
                        axisLabel: {
                            formatter: '{value}'
                        },
                        axisLine:{
                            show :true,
                            lineStyle:{
                                color:'#fff'
                            }
                        }
                    }
                ],
                series: [
                    {
                        name: '销售',
                        type: 'bar',
                        tooltip: {
                            valueFormatter: function (value) {
                                return value ;
                            }
                        },
                        data: this.ser3.data,
                        barWidth :'15%' //柱状体的宽度
                    },
                    {
                        name: '营收',
                        type: 'bar',
                        // tooltip: {
                        //     valueFormatter: function (value) {
                        //         return value;
                        //     }
                        // },
                        data: this.ser4.data,
                        barWidth :'15%' //柱状体的宽度
                    },
                    {
                        name: '销售',
                        type: 'line',
                        // yAxisIndex: 1,
                        tooltip: {
                            valueFormatter: function (value) {
                                return value;
                            }
                        },
                        data:this.ser1.data
                    },
                    {
                        name: '营收',
                        type: 'line',
                        // yAxisIndex: 1,
                        tooltip: {
                            valueFormatter: function (value) {
                                return value;
                            }
                        },
                        data:this.ser2.data
                    }
                ]
            };
            mcChart.setOption(option)
            //让图表自适应
            // window.addEventListener("resize",function(){
            //     myChart.resize()  // myChart 是实例对象
            // })
        },
        oBox(val){
            this.getapp(val)
        },
        prevstep(){
            this.$router.push({name:'StudentPage'})
        }
    },
    mounted() {
        this.getapp(1)
    }
}
</script>
<style scoped>
@import './../../../assets/css/student/A/Productplan.css';
</style>
<style>
._li_input .el-input__inner{
    text-align: center;
    background: transparent;
    border-radius: 0;
    border: none;
    padding: 0 10px;
    box-sizing: border-box;
    color: #fff;
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
}
.Prod_to_div .el-radio__input{
    float: right;
    margin-top: 3px;
}
.Prod_to_div .el-radio__input.is-checked+.el-radio__label{
    color: #13C6BF;
}
.Prod_to_div .el-radio__input.is-checked .el-radio__inner{
    border-color: #13C6BF;
    background: #13C6BF;
}
.Prod_to_div .el-radio__label{
    float: right;
    font-size: 12px;
    color: #fff;
    padding-right: 5px;
    padding-left: 0;
}
.Prod_to_div .el-radio{
    width: 100%;
    overflow: hidden;
    margin-right: 0;
    line-height: 22px;
}
.Prod_to_div .el-radio__inner{
    border-radius: 2px;
    display: inline-block;
    position: relative;
    border: 1px solid #dcdfe6;
    border-top-color: rgb(220, 223, 230);
    border-right-color: rgb(220, 223, 230);
    border-bottom-color: rgb(220, 223, 230);
    border-left-color: rgb(220, 223, 230);
    border-radius: 2px;
    box-sizing: border-box;
    width: 14px;
    height: 14px;
    background-color: #fff;
    z-index: 1;
    transition: border-color .25s cubic-bezier(.71,-.46,.29,1.46),background-color .25s cubic-bezier(.71,-.46,.29,1.46);
}
.el-radio__input.is-disabled.is-checked .el-radio__inner::after{
    box-sizing: content-box;
    content: "";
    border: 1px solid #fff;
    border-left: 0;
    border-top: 0;
    height: 7px;
    left: 4px;
    position: absolute;
    top: 1px;
    transform: rotate(45deg) scaleY(0);
    width: 3px;
    transition: transform .15s ease-in .05s;
    transform-origin: center;
    border-radius: 0;
    background: transparent;
}
.Prod_to_div .el-radio__inner::after{
    box-sizing: content-box;
    content: "";
    border: 1px solid #fff;
    border-left: 0;
    border-top: 0;
    height: 7px;
    left: 4px;
    position: absolute;
    top: 1px;
    transform: rotate(45deg) scaleY(0);
    width: 3px;
    transition: transform .15s ease-in .05s;
    transform-origin: center;
    border-radius: 0;
    background: transparent;
}
.Prod_to_div .el-radio__input.is-checked .el-radio__inner:after {
    transform: rotate(45deg) scaleY(1);
}
</style>